import { graphql, Link } from 'gatsby';
import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from 'react-share';
import qs from 'qs';
import random from 'lodash.random';
import merge from 'lodash.merge';

import {
  translationsFactory,
  TranslationsContext,
  Translation,
} from 'settings/i18n';
import ClientOnly from 'components/ClientOnly/ClientOnly';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import {
  CompletedScreenWrapper,
  CompletedQuizImage,
  CompletedHeading,
  QuizScoreText,
  ShareQuizResultsText,
  SocialMediaIcons,
  SocialMediaIcon,
  ActionButtons,
  TryOtherQuizzesButton,
  EducationButton,
} from 'components/EducationQuiz/styled';
import quizCongratulations from 'images/quiz-congratulations.svg';
import quizFacebookIcon from 'images/quiz-facebook-icon.svg';
import quizRedditIcon from 'images/quiz-reddit-icon.svg';
import quizTwitterIcon from 'images/quiz-twitter-icon.svg';
import useLinkCreator from 'hooks/useLinkCreator';
import { useIsRtl } from 'hooks/useCurrentLocale';
import { useLocation } from 'hooks/useLocation';
import { Skew } from 'components/Button/FilledButton';
import useWindowSize from 'hooks/useWindowSize';
import menuLinks from 'settings/menuLinks';

const BASE_URL = process.env.BASE_URL as string;

function useTryOtherQuizzesUrl() {
  const availableQuizUrls = [
    'quiz-blockchain',
    'quiz-mining',
    'quiz-cryptocurrency',
    'quiz-wallet',
    'quiz-trading',
  ];

  const index = random(availableQuizUrls.length - 1);

  return availableQuizUrls[index];
}

const EducationQuizResults = ({
  data,
}: {
  data: {
    translations: Translation;
    education: Translation;
    homepage: Translation;
    common: Translation;
    metadata: SEOMeta;
  };
}) => {
  const location = useLocation();
  const isRtl = useIsRtl();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { width } = useWindowSize();
  const createLink = useLinkCreator();

  /* eslint-disable no-restricted-globals */
  const isScoreCorrect = !isNaN((queryParams.score as unknown) as number);
  const score = isScoreCorrect ? Number(queryParams.score) : 0;

  const tryOtherQuizzesUrl = useTryOtherQuizzesUrl();

  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.translations?.translation,
        ...data?.education?.translation,
        ...data?.common?.translation,
        ...data?.homepage?.translation,
      },
    },
  });

  const SOCIAL_MEDIA_SHARE_QUIZ_RESULT_URL = `${BASE_URL}/quiz-results${qs.stringify(
    { score },
    { addQueryPrefix: true },
  )}`;
  const isMobile = width <= 768;

  function getQuizScoreMetadata(quizScore: number) {
    if (quizScore <= 10 && quizScore >= 9)
      return {
        title: t('outstandingPerformance'),
        description: t('outstandingPerformanceDescription'),
        image: `${BASE_URL}/quiz-result-high.jpg`,
      };
    if (quizScore <= 8 && quizScore >= 5)
      return {
        title: t('yourPotentialIsEndless'),
        description: t('yourPotentialIsEndlessDescription'),
        image: `${BASE_URL}/quiz-result-mid.jpg`,
      };
    return {
      title: t('thingsCouldBeBetter'),
      description: t('thingsCouldBeBetterDescription'),
      image: `${BASE_URL}/quiz-result-low.jpg`,
    };
  }

  const quizScoreText = t('correctAnswers').replace(/\[(.*)\]/, String(score));

  return (
    <TranslationsContext.Provider value={t}>
      <SEO
        url={SOCIAL_MEDIA_SHARE_QUIZ_RESULT_URL}
        imageType="image/jpg"
        metadata={merge(data.metadata, {
          seo: {
            metaDesc: getQuizScoreMetadata(score).description,
            opengraphDescription: getQuizScoreMetadata(score).description,
            opengraphTitle: getQuizScoreMetadata(score).title,
          },
        })}
        image={getQuizScoreMetadata(score).image}
      />
      <ClientOnly>
        <EducationNavigation />
        <CompletedScreenWrapper>
          <CompletedQuizImage src={quizCongratulations} alt="congratulations" />
          <CompletedHeading
            isRtl={isRtl}
            as="h1"
            size="headingMedium"
            color="dark"
            weight="medium"
            letterSpacing="tight">
            {getQuizScoreMetadata(score).title}
          </CompletedHeading>
          <QuizScoreText isRtl={isRtl}>{quizScoreText}</QuizScoreText>

          {isScoreCorrect && (
            <>
              <ShareQuizResultsText isRtl={isRtl} as="strong">
                {t('shareYourResult')}
              </ShareQuizResultsText>
              <SocialMediaIcons>
                <FacebookShareButton url={SOCIAL_MEDIA_SHARE_QUIZ_RESULT_URL}>
                  <SocialMediaIcon src={quizFacebookIcon} alt="Facebook icon" />
                </FacebookShareButton>

                <TwitterShareButton url={SOCIAL_MEDIA_SHARE_QUIZ_RESULT_URL}>
                  <SocialMediaIcon src={quizTwitterIcon} alt="Twitter icon" />
                </TwitterShareButton>

                <RedditShareButton url={SOCIAL_MEDIA_SHARE_QUIZ_RESULT_URL}>
                  <SocialMediaIcon src={quizRedditIcon} alt="Reddit icon" />
                </RedditShareButton>
              </SocialMediaIcons>
            </>
          )}

          <ActionButtons>
            <TryOtherQuizzesButton
              isRtl={isRtl}
              skew={isMobile ? Skew['bottom-right'] : Skew['bottom-left']}
              as={Link}
              to={createLink(`/education/${tryOtherQuizzesUrl}`)}>
              {t('tryOtherQuizes')}
            </TryOtherQuizzesButton>

            <Link to={createLink(menuLinks.education)}>
              <EducationButton isRtl={isRtl} skew={Skew['top-right']}>
                {t('education')}
              </EducationButton>
            </Link>
          </ActionButtons>
        </CompletedScreenWrapper>
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default EducationQuizResults;

export const query = graphql`
  query($locale: String!) {
    translations: wpPage(
      slug: { regex: "/\\b(\\w*quiz-cryptocurrency\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...QuizTranslation
    }
    
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*education\\w*)+(-\\d+)?\\b$/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    common: wpPage(
      slug: { regex: "/\\b(\\w*quiz-blockchain\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...EducationQuizTranslation
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
    }
  }
`;
